<template>
  <div class="display-flex align-items-center hidden-extra-content">
    <v-sheet
      v-for="(item, index) in 4"
      :key="index"
      color="grey lighten-4 mr-5"
      class="pa-3"
    >
      <v-skeleton-loader
        width="250px"
        height="330px"
        class="mx-auto"
        type="card, actions"
      ></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "HomeItemSoldSkeletonLayout",
};
</script>

<style scoped>
.hidden-extra-content {
  width: 100%;
  overflow: hidden;
}
</style>